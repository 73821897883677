import styled from "@emotion/styled"
import SectionWrapper from "../section-wrapper/section-wrapper.component"
import CustomImage from "../custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  padding-bottom: 0;
  color: ${({ theme }) => theme.palette.secondary.main};
  position: relative;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: ${({ theme }) => theme.typography.pxToRem(57)};
  }
`

export const Date = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: ${({ theme }) => theme.typography.pxToRem(19)};
  color: #191d23;
  opacity: 0.5;
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(42)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(25)};
  color: #00330e;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("md")} {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    line-height: ${({ theme }) => theme.typography.pxToRem(67)};
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(44)};
  }
`

export const Image = styled(CustomImage)`
  margin-top: ${({ theme }) => theme.typography.pxToRem(44)};
  img {
    border-radius: 8px;
    object-fit: cover !important;
  }
  height: 220px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: unset;

    //height: 536px;
    img {
      border-radius: 8px;
      //height: 536px;
      object-fit: contain !important;
    }
  }
`
