import React, { useRef } from "react"
import * as S from "./articles-slider-banner.styles"

import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import Slider from "react-slick"

import Container from "@mui/material/Container"
import ArticleCard from "../../components/article-card/article-card.component"
import { graphql, useStaticQuery } from "gatsby"

import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../../node_modules/slick-carousel/slick/slick.css"
import parse from "html-react-parser"

const ArticlesSliderBanner = ({ presentSlug }) => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"))
  let slider = useRef()

  const staticQuery = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          categories {
            nodes {
              name
              slug
            }
          }
          uri
          date(formatString: "MMMM DD, YYYY")
          excerpt
          slug
          featuredImage {
            node {
              altText
              sourceUrl
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `)

  const previous = () => {
    slider.slickPrev()
  }
  const next = () => {
    slider.slickNext()
  }

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 3000,
  }

  const blogs = staticQuery.allWpPost.nodes
  const otherBlogs = blogs.filter(blog => blog.uri !== presentSlug)

  if (!blogs) return null

  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <Stack spacing={isMd ? 10.5 : 5}>
          <S.HeaderWrapper>
            <S.Title variant={"h2"}>You might enjoy</S.Title>
            {otherBlogs?.length > 3 && isMd && (
              <S.ArrowButtonsWrapper>
                <S.ArrowButton onClick={previous} disableRipple>
                  <S.ArrowWrapper>
                    <S.CustomArrow />
                  </S.ArrowWrapper>
                </S.ArrowButton>
                <S.ArrowButton onClick={next} className="right" disableRipple>
                  <S.ArrowWrapper>
                    <S.CustomArrow />
                  </S.ArrowWrapper>
                </S.ArrowButton>
              </S.ArrowButtonsWrapper>
            )}
          </S.HeaderWrapper>
          <S.CardsWrapper>
            {isMd && otherBlogs?.length > 3 ? (
              <S.CustomSlider
                ref={c => (slider = c)}
                style={{ height: "100%" }}
                {...settings}
              >
                {otherBlogs?.map((article, index) => (
                  <ArticleCard
                    key={`${article.title}-${index}`}
                    {...article}
                    slug={`/blog/${article.slug}`}
                  />
                ))}
              </S.CustomSlider>
            ) : (
              <Grid container spacing={3}>
                {otherBlogs?.map((article, index) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    key={`${article.title}-${index}`}
                  >
                    <ArticleCard {...article} slug={`/blog/${article.slug}`} />
                  </Grid>
                ))}
              </Grid>
            )}
          </S.CardsWrapper>
        </Stack>
      </Container>
    </S.Wrapper>
  )
}

export default ArticlesSliderBanner
