import React from "react"
import * as S from "./blog-header.styles"
import { Container, Stack } from "@mui/material"

const BlogHeader = ({ title, featuredImage, date }) => {
  return (
    <S.Wrapper>
      <Container>
        <Stack spacing={2}>
          <S.Date>{date}</S.Date>
          <S.Title>{title}</S.Title>
        </Stack>
        <S.Image img={featuredImage.node} alt="header-image" />
      </Container>
    </S.Wrapper>
  )
}

export default BlogHeader
