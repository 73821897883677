import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { IconButton, Typography } from "@mui/material"
import CustomButton from "../../components/custom-button/custom-button.component"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Slider from "react-slick"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding-top: ${({ theme }) => theme.typography.pxToRem(52)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: ${({ theme }) => theme.typography.pxToRem(100)};
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: ${({ theme }) => theme.typography.pxToRem(38)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 3rem;
    line-height: ${({ theme }) => theme.typography.pxToRem(58)};
  }
`

export const ArrowButton = styled(IconButton)`
  display: none;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
    &.right {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const ArrowWrapper = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 73, 20, 0.2);
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  &:hover {
    background-color: #004914;
    svg {
      fill: white;
    }
  }
`

export const ArrowButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

export const CustomSlider = styled(Slider)`
  /* the slides */
  .slick-track {
    display: flex;
    gap: 1.5rem;
  }

  .slick-list {
    margin: 0 -10px;
  }
`

export const CustomArrow = styled(ArrowBackIcon)`
  fill: #004914;
  width: 24px;
  height: 24px;
`

export const Button = styled(CustomButton)``

export const CardsWrapper = styled.div``
